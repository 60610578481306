import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import { lightGreen } from '../styles/colors';
import styled from 'styled-components';
import { minFooterHeight, minMenuHeight } from '../styles/text';

const Container = styled.section`
  width: 100%;
  min-height: calc(100vh - ${minMenuHeight + minFooterHeight}px);

  h1 {
    color: ${lightGreen};
  }

  margin: 0 auto;
  padding: 1rem;
`;

const Message = styled.article`
  max-width: 350px;
  padding: 1rem 0;
  margin: 0 auto;
`;
const Error = ({ style, title, description }) => (
  <Layout>
    <Container style={style}>
      <Message>
        <h1>{title}</h1>
        <p>{description}</p>
      </Message>
    </Container>
  </Layout>
);

Error.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
};
Error.defaultProps = {
  style: {},
  title: '-',
  description: '',
};

export default Error;
