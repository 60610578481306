import { graphql } from 'gatsby';
import React from 'react';
import Error from '../components/error';
import { Meta } from '../components/seo';

const title = 'Niet gevonden';
const description =
  'Helaas kunnen we de pagina niet vinden, probeer het later opnieuw of check het menu bovenaan voor meer informatie.';

const NotFoundPage = () => <Error title={title} description={description} />;

export default NotFoundPage;

export const Head = (props) => (
  <Meta {...props} pageContext={{ title, description }} />
);

export const query = graphql`
  {
    allContentfulParagraph(
      filter: { title: { title: { eq: "Privacy statement" } } }
    ) {
      edges {
        node {
          id
          title {
            title
          }
          content {
            raw
          }
        }
      }
    }
    allContentfulPage {
      edges {
        node {
          __typename
          id
          description
          slug
        }
      }
    }
    site {
      siteMetadata {
        formApiURL
        ...SiteMetaData
      }
    }
  }
`;
